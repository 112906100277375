import React, { useState } from 'react';

import Icon from 'components/shared/Icon';
import ToolTip from 'components/shared/ToolTip';

/**
 * CSHelpLink Component
 *
 * This component renders a help icon that, when clicked, opens a context-sensitive help (CSH) portal
 * using the MadCap `OpenHelp` function. It also provides a tooltip for additional context.
 *
 * Props:
 * @param {Object} props - The component props.
 * @param {string} props.topic - The help topic to be opened in the help portal.
 *
 * Features:
 * - Displays a question-circle icon styled with hover effects.
 * - Calls the MadCap `OpenHelp` function with the provided topic when clicked.
 * - Logs an error to the console if the MadCap CSH functionality is unavailable.
 * - Includes a tooltip with the title "Open the help portal".
 *
 * Usage:
 * This component is intended to be used wherever a help link is needed in the application.
 */
const CSHelpLink = ({ topic }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleHelpClick = e => {
    e.preventDefault();
    if (window.MadCap?.OpenHelp) {
      window.MadCap.OpenHelp(topic);
    } else {
      // eslint-disable-next-line no-console
      console.error('MadCap CSH functionality is not available.');
    }
  };

  return (
    <ToolTip title="Open the help portal">
      <Icon
        icon="question-circle"
        className="text-navy"
        style={{
          cursor: 'pointer',
          fontSize: 16,
          opacity: isHovered ? 0.7 : 1,
          transition: 'opacity 0.2s ease',
        }}
        onClick={handleHelpClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      />
    </ToolTip>
  );
};

export default CSHelpLink;
