export const FilterTypes = {
  SAND_SITES: 'sand_sites',
  WELL_SITES: 'well_sites',
  DRIVERS: 'drivers',
  DISPOSAL_SITE: 'disposal_site',
  PRODUCTION_SITE: 'production_site',
};

export const isValidFilterType = type =>
  !!Object.values(FilterTypes).find(filterType => filterType === type);
