import { useQuery } from '@tanstack/react-query';
import { getJobs, getLocations } from './queries';

/**
 * @param {{
 *  jobId: number;
 *  location: number;
 *  district: number;
 *  jobType: number;
 * }} params
 */
export const useSCMLocations = (params) =>
  useQuery({
    queryKey: ['scm', 'locations', params],
    queryFn: () => getLocations(params),
    placeholderData: [],
  });

export const useSCMJobs = () =>
  useQuery({
    queryKey: ['scm', 'jobs'],
    queryFn: () => getJobs(),
    placeholderData: [],
  });
