import { client } from 'api/client';

/**
 * @param {{
 *  jobId: number;
 *  location: number;
 *  district: number;
 *  jobType: number;
 * }} params
 * @returns
 */
export const getLocations = async (params) => {
  const { data } = await client.get('v2/supply-chain-map/locations', {
    params
  });

  if (data?.data?.locations?.length) {
    return data.data.locations;
  }
  return [];
}


export const getJobs = async (params) => {
  const { data } = await client.get('v2/supply-chain-map/jobs', {
    params
  });

  if (data?.data?.jobs?.length) {
    return data.data.jobs;
  }
  return [];
}