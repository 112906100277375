import React from 'react';
import GoogleMap, { AutomatizeMarker } from 'components/shared/GoogleMap';
import Icon from 'components/shared/Icon';
import useHomeState from 'components/views/Home/hooks/useHomeState';

import DriverInfoWindow from 'components/views/Home/DriverInfoWindow';
import SandSiteInfoWindow from 'components/views/Home/SandSiteInfoWindow';
import WellSiteInfoWindow from 'components/views/Home/WellSiteInfoWindow';
import WaterDisposalInfoWindow from 'components/views/Home/WaterDisposalInfoWindow';
import WaterProductionInfoWindow from 'components/views/Home/WaterProductionInfoWindow';

const infoWindowContent = {
  driver: item => <DriverInfoWindow order={item} />,
  well_site: item => <WellSiteInfoWindow order={item} />,
  sand_site: item => <SandSiteInfoWindow order={item} />,
  water_disposal: item => <WaterDisposalInfoWindow order={item} />,
  water_production: item => <WaterProductionInfoWindow order={item} />,
  acid_facility: item => <WaterProductionInfoWindow order={item} />, // TODO: Change to acid
};

const infoWindowTitle = {
  driver: item => (
    <>
      <Icon icon="driver" colour="white" />
      <span className="info-window__title--text">
        #
        <a
          href={`driver-details/${item.id}`}
          target="_blank"
          rel="noopener noreferrer">
          {item.id}
        </a>
        <span> | {item.name}</span>
      </span>
    </>
  ),
  well_site: item => (
    <span className="info-window__wellsite">
      <Icon icon="wellsite" colour="white" />
      <span className="info-window__title--text">
        #<a href={`well-site-details/${item.id}`}>{item.id}</a>
        <span> | {item.name}</span>
      </span>
    </span>
  ),
  sand_site: item => (
    <>
      <Icon icon="sandsite" colour="white" />
      <span className="info-window__title--text">
        #<a href={`location-details/${item.id}`}>{item.id}</a>
        <span> | {item.name}</span>
      </span>
    </>
  ),
  water_disposal: item => (
    <>
      <Icon icon="water_disposal" colour="white" />
      <span className="info-window__title--text">
        #<a href={`location-details/${item.id}`}>{item.id}</a>
        <span> | {item.name}</span>
      </span>
    </>
  ),
  water_production: item => (
    <>
      <Icon icon="water_production" colour="white" />
      <span className="info-window__title--text">
        #<a href={`well-site-details/${item.id}`}>{item.id}</a>
        <span> | {item.name}</span>
      </span>
    </>
  ),
  acid_facility: item => (
    <>
      <Icon icon="acid_facility" colour="white" />
      <span className="info-window__title--text">
        #<a href={`well-site-details/${item.id}`}>{item.id}</a>
        <span> | {item.name}</span>
      </span>
    </>
  ),
};

const HomeMap = ({ jobs }) => {
  const { setMap } = useHomeState();

  return (
    <div data-testid="home-map-container" className="home-page__map">
      <GoogleMap fitMarkers onMapUpdate={mapR => setMap(mapR)}>
        {jobs.map((item, index) => (
          <AutomatizeMarker
            position={{
              lat: Number(item.latitude),
              lng: Number(item.longitude),
            }}
            type={item.type}
            status={item.status}
            hasAlert={item.isAlerted}
            key={`${item.id}_${item.type}_${index}`}
            infoWindow={{
              title: infoWindowTitle[item.type](item),
              content: infoWindowContent[item.type](item),
              width: 380,
            }}
          />
        ))}
      </GoogleMap>
    </div>
  );
};

export default HomeMap;
