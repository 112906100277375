import React from 'react';
import HomePage from 'components/views/Home/HomePage';
import HomeStateProvider from 'components/views/Home/hooks/HomeStateProvider';

const Home = () => (
  <HomeStateProvider>
    <HomePage />
  </HomeStateProvider>
);

export default Home;
