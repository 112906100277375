import React, { useState } from 'react';
import HomeMap from 'components/views/Home/HomeMap';
import Loader from 'components/shared/Loader';

import { useSCMLocations } from 'api/v2/home';
import HomeFilters from '../HomeFilters';

const HomePage = () => {
  const [filters, setFilters] = useState({});
  const { data: locations, isLoading } = useSCMLocations(filters);

  if (isLoading) {
    return (
      <div data-testid="home-page-loading" className="page__loader">
        <Loader size={100} />
      </div>
    );
  }

  return (
    <div className="page">
      <HomeFilters filters={filters} applyFilters={setFilters} />
      <HomeMap jobs={locations} onlyAlerted={false} />
    </div>
  );
};
export default HomePage;
