import React, { useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { useCallToLocation } from 'api/v2/orders';

import Button from 'components/shared/Button';
import { jobOrderStatus } from 'config/constants';

const CurrentOrderBreakdown = ({ isVisible, setIsVisible, currentOrderBreakdown, currentOrderBreakdownStatuses }) => {
  const { mutate: callToDestination, isPending } = useCallToLocation();
  const { callToWellEnabled, modalTitle } = useMemo(() => {
    const breakdownStatusSet = new Set(currentOrderBreakdownStatuses ?? []);
    const title = breakdownStatusSet.has(jobOrderStatus.DriverAssignedButJobNotAccepted) ? 'Breakdown (Assigned & Not Accepted)' : 'Breakdown';
    const enabled = breakdownStatusSet.has(jobOrderStatus.DepartLoadingSite) || breakdownStatusSet.has(jobOrderStatus.ArrivedAtStage);

    return { callToWellEnabled: enabled, modalTitle: title };
  }, [currentOrderBreakdownStatuses]);

  const handleSubmit = (e, data) => {
    e.preventDefault();
    callToDestination(data);
  };

  return (
    <div className="inmodal">
      <Modal
        className="modal-container"
        show={isVisible}
        onHide={() => setIsVisible(false)}>
        <div>
          <Modal.Header>
            <h3 className="modal-title">{modalTitle}</h3>
          </Modal.Header>
          <Modal.Body>
            {currentOrderBreakdown.map(breakdown => (
              <div key={breakdown.commodityId}>
                <h3>
                  {breakdown.commodityName} - {breakdown.orders.length} orders
                </h3>
                <table className="table table--hover">
                  <colgroup>
                    <col style={{ width: 200 }} />
                    <col style={{ width: 200 }} />
                    <col style={{ width: 75 }} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>
                        Carrier
                      </th>
                      <th>
                        Truck #
                      </th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {breakdown.orders.map(order => (
                      <tr key={order.orderId}>
                        <td>
                          {order.carrierName}
                        </td>
                        <td>
                          {order.truck}
                        </td>
                        <td>
                          {!!callToWellEnabled && (
                            <Button
                              disabled={isPending}
                              testSelector="events_open-msg_btn"
                              theme="small"
                              colour="white"
                              onClick={(e) => { handleSubmit(e, order) }}>
                              Call to Destination
                            </Button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => setIsVisible(false)}
              colour="white"
              testSelector="info-details_close_btn">
              Close
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </div>
  );
}

export default CurrentOrderBreakdown;
