import React, { useState, useEffect } from 'react';
import { Row } from 'react-bootstrap';

import { jobOrderStatus } from 'config/constants';
import Icon from 'components/shared/Icon';
import Authorize from 'components/common/Authorize';
import CurrentOrderBreakdown from './CurrentOrderBreakdown';

const TodayInfo = ({ stats, isStagingSite }) => {
  const [breakdownModalVisible, setBreakdownModalVisible] = useState(false);
  const [currentBreakdown, setCurrentBreakdown] = useState([]);
  const [currentBreakdownStatuses, setCurrentBreakdownStatuses] = useState([]);

  useEffect(() => () => {
    setCurrentBreakdown([]);
    setCurrentBreakdownStatuses([]);
    setBreakdownModalVisible(false);
  }, []);

  return (
    <>
      <Row>
        <div className="col-lg-6" style={{ paddingLeft: 0 }}>
          <div className="ibox float-e-margins">
            <div className="ibox-title">
              <h5>Current Orders</h5>
            </div>
            <div className="ibox-content text-center">
              <div className="job-details__today-info">
                <div>
                  <h1 className="no-margins">{stats.not_accepted}</h1>
                  <div className="font-bold text-navy">
                    <small>Not Accepted</small>
                    <span>
                      <Icon
                        icon="info-circle"
                        className="text-navy"
                        onClick={e => {
                          e.preventDefault();
                          const breakdown = stats.currentOrderBreakdown[`${jobOrderStatus.DriverAssignedButJobNotAccepted}`]
                          setCurrentBreakdownStatuses([jobOrderStatus.DriverAssignedButJobNotAccepted]);
                          setCurrentBreakdown(breakdown);
                          setBreakdownModalVisible(true);
                        }}
                        style={{ cursor: 'pointer' }}
                      />
                    </span>
                  </div>
                </div>
                <div>
                  <h1 className="no-margins">{stats.enroute_to_load}</h1>
                  <div className="font-bold text-navy">
                    <small>En Route to Load</small>
                    <span>
                      <Icon
                        icon="info-circle"
                        className="text-navy"
                        onClick={e => {
                          e.preventDefault();
                          const breakdown = [
                            ...stats.currentOrderBreakdown[`${jobOrderStatus.JobAccepted}`],
                            ...stats.currentOrderBreakdown[`${jobOrderStatus.EnteringLoadingSite}`]
                          ];
                          setCurrentBreakdownStatuses([jobOrderStatus.JobAccepted, jobOrderStatus.EnteringLoadingSite]);
                          setCurrentBreakdown(breakdown);
                          setBreakdownModalVisible(true);
                        }}
                        style={{ cursor: 'pointer' }}
                      />
                    </span>
                  </div>
                </div>
                {!!isStagingSite && [
                  <div>
                    <h1 className="no-margins">{stats.enroute_to_stage}</h1>
                    <div className="font-bold text-navy">
                      <small>En Route to Stage</small>
                      <span>
                        <Icon
                          icon="info-circle"
                          className="text-navy"
                          onClick={e => {
                            e.preventDefault();
                            const breakdown = stats.currentOrderBreakdown[`${jobOrderStatus.EnRouteToStage}`];
                            setCurrentBreakdownStatuses([jobOrderStatus.EnRouteToStage]);
                            setCurrentBreakdown(breakdown);
                            setBreakdownModalVisible(true);
                          }}
                          style={{ cursor: 'pointer' }}
                        />
                      </span>
                    </div>
                  </div>,
                  <div>
                    <h1 className="no-margins">{stats.staged}</h1>
                    <div className="font-bold text-navy">
                      <small>Staging</small>
                      <span>
                        <Icon
                          icon="info-circle"
                          className="text-navy"
                          onClick={e => {
                            e.preventDefault();
                            const breakdown = stats.currentOrderBreakdown[`${jobOrderStatus.ArrivedAtStage}`];
                            setCurrentBreakdownStatuses([jobOrderStatus.ArrivedAtStage]);
                            setCurrentBreakdown(breakdown);
                            setBreakdownModalVisible(true);
                          }}
                          style={{ cursor: 'pointer' }}
                        />
                      </span>
                    </div>
                  </div>,
                ]}
                <div>
                  <h1 className="no-margins">{stats.enroute_to_well}</h1>
                  <div className="font-bold text-navy">
                    <small>En Route to Deliver</small>
                    <span>
                      <Icon
                        icon="info-circle"
                        className="text-navy"
                        onClick={e => {
                          e.preventDefault();
                          const breakdown = stats.currentOrderBreakdown[`${jobOrderStatus.DepartLoadingSite}`];
                          setCurrentBreakdownStatuses([jobOrderStatus.DepartLoadingSite]);
                          setCurrentBreakdown(breakdown);
                          setBreakdownModalVisible(true);
                        }}
                        style={{ cursor: 'pointer' }}
                      />
                    </span>
                  </div>
                </div>
                <div>
                  <h1 className="no-margins">{stats.onsite}</h1>
                  <div className="font-bold text-navy">
                    <small>Onsite</small>
                    <span>
                      <Icon
                        icon="info-circle"
                        className="text-navy"
                        onClick={e => {
                          e.preventDefault();
                          const breakdown = stats.currentOrderBreakdown[`${jobOrderStatus.EnteringWellSite}`];
                          setCurrentBreakdown(breakdown);
                          setBreakdownModalVisible(true);
                        }}
                        style={{ cursor: 'pointer' }}
                      />
                    </span>
                  </div>
                </div>
                {!!stats.auto_ordered && (
                  <div>
                    <h1 className="no-margins">{stats.auto_ordered}</h1>
                    <div className="font-bold text-navy">
                      <small>Auto Ordered</small>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Authorize
          abilityPermissions={[{ resource: 'Jobs', permissions: ['read'] }]}>
          <div className="col-lg-3">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h5>Current Issues</h5>
              </div>

              <div className="ibox-content text-center">
                <div className="row">
                  <div className="col-md-4">
                    <h1 className="no-margins">{stats.projected_to_be_late}</h1>
                    <div className="font-bold text-navy">
                      <small>Projected to be Late</small>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <h1 className="no-margins">{stats.late}</h1>
                    <div className="font-bold text-navy">
                      <small>Late</small>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <h1 className="no-margins">{stats.truckDown}</h1>
                    <div className="font-bold text-navy">
                      <small>Truck(s) Down</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Authorize>
        <div className="col-lg-3" style={{ paddingRight: 0 }}>
          <div className="ibox float-e-margins">
            <div className="ibox-title">
              <h5>CURRENT COMMODITIES</h5>
            </div>
            <div
              className="ibox-content text-center"
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}>
              {stats.commodities.map(item => (
                <div
                  key={`commodities-${item.commodityId}`}
                  style={{ padding: '0 5px 10px 5px' }}>
                  <h1 className="no-margins" style={{ cursor: 'pointer' }}>
                    {item.openOrderCount}
                  </h1>
                  <div className="font-bold text-navy">
                    <small>{item.commodityName}</small>
                  </div>
                </div>
              ))}
              {!stats.commodities.length && <div>No active orders</div>}
            </div>
          </div>
        </div>
      </Row>
      <CurrentOrderBreakdown
        currentOrderBreakdown={currentBreakdown}
        currentOrderBreakdownStatuses={currentBreakdownStatuses}
        isVisible={breakdownModalVisible}
        setIsVisible={setBreakdownModalVisible}
      />
    </>

  )
};
export default TodayInfo;
